<template>
  <div class="user">
    <v-app-bar app flat color="grey lighten-4" class="px-2">
      <v-img
        alt="100 Miles Logo"
        class="mr-2 shrink"
        contain
        src="../../assets/100logo.png"
        transition="scale-transition"
        max-height="36"
        max-width="36"
      />
      <v-spacer></v-spacer>
      <v-btn plain to="/user/profilesetting">
        <!-- <div>{{userProfile.Name}}</div> -->
        <v-avatar size="36px">
          <v-img
            :src="
              imgURL + '/' + userProfile.Id + '/avatar/' + userProfile.avatar
            "
          ></v-img>
        </v-avatar>
      </v-btn>
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" height="100%" absolute temporary>
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>John Leider</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="(item, index) in navigationBar" :key="index" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="px-2 py-2">
      <router-view></router-view>
    </v-main>

    <v-bottom-navigation flat app>
      <v-btn
        v-for="(nav, index) in navigationBar"
        :key="index"
        :to="nav.link"
        color="primary"
        plain
      >
        <span>{{ nav.title }}</span>

        <v-icon>{{ nav.icon }}</v-icon>
      </v-btn>
      <v-btn icon @click="logOut">
        <span>Sign Out</span>
        <v-icon>fas fa-power-off</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "User",
  data() {
    return {
      drawer: null,
      imgURL: "https://100milesrealtors.com/routes/storage/images",
      navigationBar: [
        {
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
          link: "/user/userdashboard",
        },
        { title: "Home", icon: "fas fa-home", link: "/user/home" },
        { title: "Add", icon: "fas fa-plus-circle", link: "/user/addproperty" },
      ],
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.authenticate.userProfile,
    }),
  },
  mounted() {
    this.getUser();
  },
  methods: {
    ...mapActions(["logOut", "getUser"]),
  },
};
</script>
<style scoped>
.user {
  height: 100%;
}
</style>
